import firebase from 'firebase/app'
import 'firebase/firestore'

const database = firebase.firestore()

const categoriesCollection = database.collection('newsCategories')

// Use for getting specific news category
const getCategory = async (categoryId) => {

    const notFound = {
        title: {en: "Undefined", th: "ไม่ระบุ"}
    }

    if (!categoryId) return notFound
    const categoryDocument = await categoriesCollection.doc(categoryId).get()
    return categoryDocument.data()
}

//  Use for getting all news categories
const getCategories = async () => {
    const snapshot = await categoriesCollection.orderBy("order").get()
    return snapshot.docs.map(doc => {
        let data = doc.data()
        data.id = doc.id
        return data
    })
}

export {
    getCategory,
    getCategories
}