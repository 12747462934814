<template>
    <div>
        <h3 class="mb-4">{{$t('general')}}</h3>
        <!-- <h3 v-if="category" class="mb-4">{{$t('title', category.title)}}</h3> -->
        <div v-if="newsList.length" class="row gy-4">
            <div class="col-12 col-md-6 col-xl-4" v-for="(news, index) in lastestNews" :key="index">
                <router-link :to="`/news/${news.path}`" custom v-slot="{ navigate }">
                    <div @click="navigate" class="box card h-100">
                        <img :src="loadImage(news.banner.url)" alt="News article banner" class="card-img-top">
                        <div class="card-body d-flex flex-column">
                            <h6 class="line-2 text-capitalize text-darkblue mb-3">
                                {{$t("title", news.title) ? $t("title", news.title) : news.title.en}}
                            </h6>
                            <div class="small text-grey d-flex flex-column mt-auto">
                                <span>
                                    <i class="fa fa-clock me-2"></i>
                                    {{_formatDateRelation(news.postDate.seconds)}}
                                </span>
                                <span>
                                    <i class="fas fa-pencil-alt me-2"></i>
                                    {{news.authorFullname}}
                                </span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div v-else class="text-center py-3">
            <div class="my-2">
                <i class="fas fa-folder-open fa-5x"></i>
            </div>
            <div class="fs-4 text-muted">{{$t("empty")}}</div>
        </div>
    </div>
</template>
<style scoped>
.box {
    cursor: default;
    transition: .25s;
}
.box:hover {
    box-shadow: 0 .5rem 1rem rgba(39, 39, 39, .15);
}
</style>
<i18n>
{
    "en": {
        "title": "{en}",
        "general": "General",
        "empty": "We will update news soon."
    },
    "th": {
        "title": "{th}",
        "general": "ข่าวสารทั่วไป",
        "empty": "เราจะอัพเดตข่าวสารในเร็ว ๆ นี้"
    }
}
</i18n>
<script>
import { getFullname } from '@/helpers/user'
import * as newsDb from '@/helpers/news'

export default {
    data() {
        return {
            newsList: [],
            category: undefined
        }
    },
    computed: {
        lastestNews() {
            if (!this.newsList) return this.newsList
            const list = this.newsList
            const sortLastest = (a, b) => (a.postDate > b.postDate) ? -1 : 1
            return list.sort(sortLastest)
        }
    },
    methods: {
        async getNews() {
            // TODO: query news base on selected category
            let articles = await newsDb.getNewsArticles()
            for (let article of articles) {
                article.authorFullname = await getFullname(article.authorId)
            }
            this.newsList = articles
        },
        loadImage(url) {
            if (url === null) return this._placeholder.banner
            return url
        }
    },
    async created() {
        await this.getNews()
    }
}
</script>