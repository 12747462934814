<template>
    <div>
        <div class="card">
            <ul class="list-group list-group-flush">
                <li class="category-title list-group-item disabled">
                    <div class="h5 mb-0 py-2">ประเภทข่าวสาร</div>
                </li>
                <li class="category-nav active-nav list-group-item">
                    {{$t('general')}}
                </li>
                <!-- <li @click="navigate(cat)" v-for="(cat, index) in categories" :key="index" :class="{'active-nav': isSelected(cat.id)}" class="category-nav list-group-item">
                    {{$t('title', cat.title)}}
                </li> -->
            </ul>
        </div>
    </div>
</template>
<i18n>
{
    "en": {
        "title": "{en}",
        "general": "General"
    },
    "th": {
        "title": "{th}",
        "general": "ข่าวสารทั่วไป"
    }
}
</i18n>
<style scoped>
.category-nav {
    cursor: pointer;
    border-left: 4px solid rgba(0, 0, 0, 0);
    transition: .125s;
}
.category-nav.active-nav {
    border-left-color: var(--navy);
    color: var(--navy);
}
.category-title {
    color: black;
}
</style>
<script>
import * as categoryDb from '@/helpers/newsCategory'

export default {
    emits: [
        'navigate'
    ],
    data() {
        return {
            selected: null,
            categories: []
        }
    },
    methods: {
        navigate(category) {
            const query = {category: category.id}
            this.setCurrentQuery(category)
            this.$router.push({name: 'News', query: query})
        },
        setCurrentQuery(category) {
            this.$emit('navigate', category)
            this.selected = category.id
        },
        isSelected(id) {
            return id === this.selected
        }
    },
    async created() {
        this.categories = await categoryDb.getCategories()

        // Set current query base on url
        // const queryId = this.$route.query.category
        // this.setCurrentQuery(this.categories.find(category => category.id === queryId))
    }
}
</script>