<template>
    <div>
        <div class="container my-5">
            <div class="row gy-5">
                <div class="col-12 col-lg-3">
                    <Category @navigate="setCategory" />
                </div>
                <div class="col-12 col-lg-9">
                    <ListOfNews ref="listOfNews" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Category from './Category.vue'
import ListOfNews from './ListOfNews.vue'

export default {
    components: {
        Category,
        ListOfNews
    },
    methods: {
        setCategory(category) {
            // this.$refs.listOfNews.category = category
        }
    }
}
</script>